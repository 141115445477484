// extracted by mini-css-extract-plugin
export var aligned = "global-module--aligned--m7y6A";
export var between = "global-module--between--CkuL8";
export var bgLight = "global-module--bgLight--OuuWt";
export var centered = "global-module--centered--InSwY";
export var column = "global-module--column--eDMNb";
export var container = "global-module--container--08bUt";
export var flex = "global-module--flex--0O66w";
export var sectionInner = "global-module--sectionInner--VeKQp";
export var stretch = "global-module--stretch--lcNK+";
export var wrap = "global-module--wrap--ZmwsT";