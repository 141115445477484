/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure main [content] component.
 */

// Core dependencies
import React from "react";

// Styles
import {
  centered,
  column,
  container,
  flex,
} from "../../styles/global.module.css";

let classNames = require("classnames");

class Main extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <section>
        <div className={classNames(flex, column, centered, container)}>
          {children}
        </div>
      </section>
    );
  }
}

export default Main;
