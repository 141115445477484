/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure main wrapper for main [content] component.
 */

// Core dependencies
import React from "react";

// App dependencies
import MainContent from "./mainContent";

// Styles
import { home } from "../home/home.module.css";
import { main } from "./main.module.css";

const classNames = require("classnames");

class Main extends React.Component {
  render() {
    const { children, homePage } = this.props;
    return (
      <main className={classNames(main, { [home]: homePage })}>
        {homePage ? children : <MainContent>{children}</MainContent>}
      </main>
    );
  }
}

export default Main;
