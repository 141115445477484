/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure layout component; works as wrapper around site component.
 */

// Core dependencies
import Helmet from "react-helmet";
import React from "react";

// App dependencies
import Footer from "./footer/footer";
import Header from "./header/header";
import Main from "./main/main";

// Styles
import "../styles/vars.module.css";
import { menuOpen, site } from "./layout.module.css";

let classNames = require("classnames");

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { noScroll: false };
  }

  onMenuOpen = (event) => {
    this.setState({ noScroll: !event });
  };

  render() {
    const { children, homePage } = this.props;
    return (
      <div>
        <Helmet>
          <title>LicenseSure</title>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round"
          />
        </Helmet>
        <div className={classNames({ [menuOpen]: this.state.noScroll }, site)}>
          <Header onMenuOpen={this.onMenuOpen.bind(this)} />
          <Main homePage={homePage}>{children}</Main>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Layout;
