import { useStaticQuery, graphql } from "gatsby";

export const HeaderStaticQuery = () => {
  const { allSiteMapYaml } = useStaticQuery(
    graphql`
      query HeaderStaticQuery {
        allSiteMapYaml(
          filter: { navigation: { location: { regex: "/h/" } } }
          sort: { fields: navigation___order, order: ASC }
        ) {
          edges {
            node {
              name
              path
              navigation {
                description
                location
                order
              }
            }
          }
        }
      }
    `
  );
  return allSiteMapYaml.edges.map((e) => e.node);
};
