/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure footer component.
 */

// Core dependencies
import { Link } from "gatsby";
import React from "react";

// Images
import facebook from "../../../images/socials/whiteFacebook.png";
import linkedIn from "../../../images/socials/whiteLinkedIn.png";
import twitter from "../../../images/socials/whiteTwitter.png";

// Styles
import { footer, nav, socialNav, socials } from "./footer.module.css";
import { container, flex } from "../../styles/global.module.css";

let classNames = require("classnames");

export default function Footer() {
  return (
    <div>
      <div className={footer}>
        <div className={container}>
          <div className={nav}>
            <ul className={flex}>
              <li>
                <h4>Education Center</h4>
                <ul>
                  <li>
                    <Link to="/education#business-development-topics">
                      Business Development Topics
                    </Link>
                  </li>
                  <li>
                    <Link to="/education#financial-management-resources">
                      Financial Management Resources
                    </Link>
                  </li>
                  <li>
                    <Link to="/education#forming-and-operating-your-business">
                      Forming And Operating Your Business
                    </Link>
                  </li>
                  <li>
                    <Link to="/education#intellectual-property-issues">
                      Intellectual Property Issues
                    </Link>
                  </li>
                  <li>
                    <Link to="/education#managing-your-existing-business">
                      Managing Your Existing Business
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <h4>Services</h4>
                <ul>
                  <li>
                    <Link to="/services#entity-formation">
                      Entity Formation
                    </Link>
                  </li>
                  <li>
                    <Link to="/services#foreign-qualification">
                      Foreign Qualification
                    </Link>
                  </li>
                  <li>
                    <Link to="/services#registered-agent">
                      Registered Agent
                    </Link>
                  </li>
                  <li>
                    <Link to="/services#report-preparation">
                      Report Preparation
                    </Link>
                  </li>
                  <li>
                    <Link to="/services#document-filing">Document Filing</Link>
                  </li>
                  <li>
                    <Link to="/services#document-retrieval">
                      Document Retrieval
                    </Link>
                  </li>
                  <li>
                    <Link to="/services#consulting">Consulting</Link>
                  </li>
                  <li>
                    <Link to="/services#analysis">Analysis</Link>
                  </li>
                </ul>
              </li>
              <li>
                <h4>
                  <Link to="/about">About</Link>
                </h4>
                <h4>
                  <Link to="/contact">Contact</Link>
                </h4>
              </li>
              <li>
                <h4>Resources</h4>
                <ul>
                  <li>
                    <Link to="/quick-links/start-your-firm">
                      Start Your Firm
                    </Link>
                  </li>
                  <li>
                    <Link to="/quick-links/expand-your-firm">
                      Expand Your Firm
                    </Link>
                  </li>
                  <li>
                    <Link to="/#map">Interactive Map</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={socialNav}>
            <div>
              <ul className={flex}>
                <li>
                  <Link to="/terms">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/disclaimer">Disclaimer</Link>
                </li>
                <li>&copy; 2019 LicenseSure LLC</li>
              </ul>
            </div>
            <div className={classNames(flex, socials)}>
              <a
                href="https://www.linkedin.com/company/licensesure-llc"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={linkedIn} alt="Linkedin" />
              </a>
              <a
                href="https://www.facebook.com/LicenseSureLLC"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={facebook} alt="Facebook" />
              </a>
              <a
                href="https://twitter.com/licensesure"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={twitter} alt="Twitter" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
