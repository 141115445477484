/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure header component.
 */

// Core dependencies
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

// App dependencies
import { HeaderStaticQuery } from "../../hooks/headerQuery";

// Images
import logo from "../../../images/logoLS.png";

// Styles
import { active, header, nav } from "./header.module.css";
import { container } from "../../styles/global.module.css";

let classNames = require("classnames");

export default function Header({ onMenuOpen }) {
  const links = HeaderStaticQuery();
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    onMenuOpen(!showNav);
  }, [onMenuOpen, showNav]);

  return (
    <div className={header}>
      <div className={container}>
        <Link to="/">
          <img src={logo} alt="LicenseSure" />
        </Link>
        {!showNav && (
          <i
            className={"material-icons-round"}
            onClick={() => setShowNav(true)}
            role="presentation"
          >
            menu
          </i>
        )}
        {showNav && (
          <i
            className={"material-icons-round"}
            onClick={() => setShowNav(false)}
            role="presentation"
          >
            close
          </i>
        )}
        <ul className={classNames({ [nav]: showNav })}>
          {links.map(({ name, path, navigation }) => (
            <li key={name}>
              <Link to={path} activeClassName={active} partiallyActive={true}>
                <span>{name}</span>
                <span>{navigation.description}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
